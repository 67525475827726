/**
 *
 * FormWrapper
 * @author Chad Watson
 *
 *
 */
import { BORDER_RADIUS, GUTTER, GUTTER_LARGE } from "constants/index";
import { themePanelBorder } from "containers/Theme";
import styled, { css } from "styled-components/macro";
import { media } from "utils/styles";
const FormWrapper = styled.div`
  width: 100%;
  margin-bottom: ${GUTTER}px;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  background: white;

  ${media.small`
    ${({ hasActionRow }) =>
      !hasActionRow &&
      css`
        padding-top: 25px;
      `}
  `} &:last-child {
    ${media.xxlarge`
      margin-bottom: ${GUTTER_LARGE * 2}px;
    `};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      position: relative;
      pointer-events: none;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        opacity: 0.3;
        z-index: 50;
      }
    `};
`;
export default FormWrapper;
