/**
 *
 * SectionHeader
 * @author Chad Watson
 *
 */

import NakedButton from "components/NakedButton";
import StatusIndicators from "components/StatusIndicators";
import {
  themeBoxShadow,
  themeGrayAccent,
  themeGrayMedium,
  themeGrayMediumLight,
  themePanelBorder,
  themeRgba,
} from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components/macro";
import { media } from "utils/styles";
import messages from "./messages";

export { default as ActionButton } from "./ActionButton";
export { default as ActionButtonLink } from "./ActionButtonLink";
export { default as BackButton } from "./BackButton";
export { default as RefreshButton } from "./RefreshButton";

export const BASE_HEIGHT = 56;
export const SMALL_HEIGHT = 66;

export const SectionHeader = (props) => {
  const title = props.title;
  const actionButtons = props.actionButtons;
  const actionButtonsRight = props.actionButtonsRight;
  const onAdd = props.onAdd;
  const showLoading = props.showLoading;
  const formatMessage = useIntl().formatMessage;
  const disabled = props.disabled;

  return props ? (
    <Root>
      <Header>
        <Section>
          <Title>{title}</Title>
          {onAdd && (
            <AddButton
              title={formatMessage(messages.add)}
              onClick={onAdd}
              disabled={disabled}
            />
          )}
          {!!actionButtons.length &&
            actionButtons.map((actionButton, index) =>
              actionButton
                ? React.cloneElement(actionButton, {
                    key: index.toString(),
                  })
                : null
            )}
          {showLoading && <StatusIndicators loading={showLoading} />}
        </Section>
        {!!actionButtonsRight.length && (
          <RightSection key="right-section">
            {!!actionButtonsRight.length &&
              actionButtonsRight.map((actionButton, index) =>
                actionButton
                  ? React.cloneElement(actionButton, {
                      key: index.toString(),
                    })
                  : null
              )}
          </RightSection>
        )}
      </Header>
    </Root>
  ) : null;
};

SectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
  actionButtonsRight: PropTypes.arrayOf(PropTypes.node),
  onAdd: PropTypes.func,
  showLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

SectionHeader.defaultProps = {
  actionButtons: [],
  actionButtonsRight: [],
  showLoading: false,
  disabled: false,
};

export default SectionHeader;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${BASE_HEIGHT}px;

  ${media.smallMax`
    margin-bottom: 10px;
  `};
  ${media.small`
    min-height: ${SMALL_HEIGHT}px;
  `};
`;
const Header = styled.header`
  border-bottom: 2px solid ${themeRgba(0.25, themeGrayMedium)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 10px 0;
`;
const Section = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;

  ${media.smallMax`
    justify-content: space-between;
  `};
`;
const RightSection = styled(Section)`
  ${media.smallMax`
    flex-direction: column;
    align-items: stretch;

    & > * {
      min-height: 32px;
    }

    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  `}

  ${media.small`
    justify-content: flex-end;

    & > * + * {
      margin-left: 5px;
    }
  `}
`;
const Title = styled.h2`
  margin: 0 10px 0 0;
  color: ${themeGrayMedium};
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
`;
export const AddButton = styled(NakedButton)`
  position: relative;
  width: 1em;
  height: 1em;
  margin-right: 5px;
  border-radius: 50%;
  border: ${themePanelBorder};
  background: white;
  font-size: 1.75em;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 0.15em;
    background: ${({ disabled }) =>
      disabled ? themeGrayMediumLight : themeGrayMedium};
    font-size: 0.43em;
  }

  &::before {
    transform: translate(-50%, -50%);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:hover {
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.05)")};
    box-shadow: ${({ disabled }) =>
      disabled ? "none" : `${themeBoxShadow(themeRgba(0.5, themeGrayAccent))}`};
  }

  &:active {
    transform: scale(1.1);
    box-shadow: ${themeBoxShadow(themeRgba(0.8, themeGrayAccent))};
  }

  &[disabled] {
    transform: unset;
    box-shadow: unset;
  }
`;
