/**
 *
 * BigField
 * @author Chad Watson
 *
 */

import withErrorMessage from "components/Form/withErrorMessage";
import { BORDER_RADIUS } from "constants/index";
import { themeGray, themeGrayLight } from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";
import Help from "./Help";
import HelpTitle from "./HelpTitle";

const BigField = ({
  id,
  value,
  label,
  errorMessage,
  validate,
  disabled,
  tooltip,
  className,
  inputRef,
  ...rest
}) => {
  const hasError = validate ? !validate(value) : false;

  return (
    <Wrapper htmlFor={id} className={className}>
      <Label hasError={hasError} disabled={disabled}>
        {tooltip ? (
          <span>
            <HelpTitle>{label}</HelpTitle>
            <Help>{tooltip}</Help>
          </span>
        ) : (
          label
        )}
      </Label>
      <Input
        {...rest}
        value={value}
        id={id}
        hasError={hasError}
        disabled={disabled}
        ref={inputRef}
      />
    </Wrapper>
  );
};

BigField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMessage: PropTypes.node,
  disabled: PropTypes.bool,
  tooltip: PropTypes.node,
  className: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const BigFieldWithErrorMessage = withErrorMessage(BigField);

const ComposedBigFieldWithErrorMessage = ({
  validate,
  value,
  errorMessage,
  ...rest
}) => (
  <BigFieldWithErrorMessage
    {...rest}
    validate={validate}
    value={value}
    errorMessage={(validate && !validate(value) && errorMessage) || undefined}
  />
);

ComposedBigFieldWithErrorMessage.propTypes = {
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMessage: PropTypes.node,
};

/**
 * @deprecated Prefer `LargeInputField` instead
 */
export default ComposedBigFieldWithErrorMessage;

const INPUT_BORDER_WIDTH = 2;
const INPUT_FONT_SIZE = 24;
const INPUT_LINE_HEIGHT = INPUT_FONT_SIZE * 1.5;
export const INPUT_HEIGHT = INPUT_LINE_HEIGHT + INPUT_BORDER_WIDTH * 2; // eslint-disable-line no-mixed-operators

const Wrapper = styled.label`
  display: block;
  flex: 1;
  position: relative;
  text-align: center;
`;
const Input = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0.5em;
  border: ${INPUT_BORDER_WIDTH}px solid
    ${({ hasError, theme }) => (hasError ? theme.failure : theme.grayAccent)};
  border-radius: ${BORDER_RADIUS};
  color: ${themeGray};
  background: white;
  font-size: ${INPUT_FONT_SIZE}px;
  font-weight: bold;
  line-height: ${INPUT_LINE_HEIGHT}px;
  text-align: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.failure : theme.primary};
    outline: none;
    box-shadow: none;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[disabled] {
    background: ${themeGrayLight};
    cursor: not-allowed;
  }
`;
const Label = styled.span`
  padding-top: 5px;
  color: ${({ hasError, theme }) =>
    hasError ? theme.failure : theme.grayMedium};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.04em;
  line-height: 1.5;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "default")};
`;
