/**
 *
 * FieldWithLabel
 * @author Chad Watson
 *
 *
 */
import Tooltip, { TooltipErrorMessage } from "components/Tooltip";
import moment from "moment";
import PropTypes from "prop-types";
import { always, ifElse, is, prop } from "ramda";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import momentPropTypes from "react-moment-proptypes";
import styled from "styled-components/macro";
import DateField from "./DateField";
import Field from "./Field";
const Root = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ labelPosition }) =>
      labelPosition === LabelPosition.Left ? "0px" : "10px"};
  }
  display: ${({ labelPosition }) =>
    labelPosition === LabelPosition.Left ? "flex" : "block"};
`;
const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1.5rem; /* Matching root line-height */
  margin-bottom: ${ifElse(prop("withBorder"), always(5), always(2))}px;
  border-bottom: ${({ theme }) =>
    ifElse(
      prop("withBorder"),
      always(`2px solid ${theme.grayAccent}`),
      always("none")
    )};
`;
const Label = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: ${ifElse(
    prop("withBorder"),
    always("uppercase"),
    always("initial")
  )}px;
  line-height: 1.1;
  padding-right: ${({ labelPosition }) =>
    labelPosition === LabelPosition.Left ? "2rem" : "0rem"};
`;
export const LabelPosition = {
  Top: "top",
  Left: "left",
};
/**
 *
 * @deprecated Prefer `FieldWithErrors` instead
 * @type {(props: {
 *   id: string;
 *   label: React.ReactNode | import('react-intl').MessageDescriptor | null;
 *   labelPosition?: string;
 *   errorMessage?: React.ReactNode | import('react-intl').MessageDescriptor | null;
 *   children?: React.ReactNode;
 *   className?: string;
 *   help?: React.ReactNode;
 *   separateWithBorder?: boolean;
 *   required?: boolean;
 *   value?: string;
 *   type?: "text" | "textarea" | "tel" | "email";
 *   ref?: any;
 *   zIndex?: number
 * } & React.InputHTMLAttributes<any>) => JSX.Element} */
const FieldWithLabel = React.forwardRef(
  (
    {
      id,
      label,
      labelPosition = LabelPosition.Top,
      errorMessage = null,
      children = null,
      className = "",
      help = null,
      separateWithBorder = true,
      required = false,
      value = "",
      type = "text",
      zIndex,
      ...rest
    },
    ref
  ) => (
    <Root className={className} labelPosition={labelPosition}>
      <LabelContainer withBorder={separateWithBorder}>
        <Label
          htmlFor={id}
          withBorder={separateWithBorder}
          labelPosition={labelPosition}
        >
          {React.isValidElement(label) || is(String, label) ? (
            label
          ) : (
            <FormattedMessage {...label} />
          )}
          {required && "*"}
        </Label>
        {help}
      </LabelContainer>
      <Tooltip
        anchor={({ registerAnchor }) => (
          <div ref={registerAnchor}>
            {children ||
              (moment.isMoment(value) ? (
                <DateField
                  error={!!errorMessage}
                  {...rest}
                  id={id}
                  value={value}
                  ref={ref}
                />
              ) : (
                <Field
                  error={!!errorMessage}
                  {...rest}
                  type={type === "textarea" ? undefined : type}
                  as={type === "textarea" ? "textarea" : undefined}
                  id={id}
                  value={value}
                  ref={ref}
                />
              ))}
          </div>
        )}
        dark
        open={!!errorMessage}
        zIndex={zIndex}
      >
        <TooltipErrorMessage>
          {!!errorMessage &&
            (React.isValidElement(errorMessage) || is(String, errorMessage) ? (
              errorMessage
            ) : (
              <FormattedMessage {...errorMessage} />
            ))}
        </TooltipErrorMessage>
      </Tooltip>
    </Root>
  )
);
FieldWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  children: PropTypes.node,
  className: PropTypes.string,
  required: PropTypes.bool,
  separateWithBorder: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    momentPropTypes.momentObj, // eslint-disable-line react/no-typos
  ]),
};
export default FieldWithLabel;
export const SimpleFieldWithLabel = ({
  label,
  separateWithBorder,
  children,
}) => (
  <Root>
    <LabelContainer withBorder={separateWithBorder}>
      <Label withBorder={separateWithBorder}>{label}</Label>
    </LabelContainer>
    {children}
  </Root>
);
SimpleFieldWithLabel.defaultProps = {
  separateWithBorder: false,
};
